<template>
  <ContentWrapper v-if="headerFooter.header && headerFooter.footer">
    <div ref="observerElement" class="observer-element" />

    <VAppBar
      class="text-caption header-desktop"
      :class="{ 'fixed-top': true, hidden: isHidden, safari: isSafari }">
      <v-row>
        <v-col class="top pb-0 border-b-sm px-md-0" cols="12">
          <v-container class="py-1 px-md-0" :fluid="isSafari">
            <v-row justify="space-between" align="center">
              <v-col cols="auto" class="px-md-0">
                {{ headerFooter.header.slogan }}
              </v-col>
              <v-col cols="auto">
                <v-list
                  id="list-claims"
                  class="d-none d-lg-flex pa-0"
                  role="list"
                  density="compact">
                  <v-list-item
                    v-for="claim in headerFooter.header.claims"
                    :key="claim"
                    height="32"
                    min-height="25px"
                    class="pt-0">
                    <v-icon class="text-primary">mdi-check</v-icon>
                    {{ claim }}
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="auto" class="px-md-0">
                {{ headerFooter.header.hotline_label }}
                <a
                  :href="`tel:${sanitizePhone(headerFooter.header.phone)}`"
                  class="text-decoration-none font-weight-bold">
                  {{ sanitizePhone(headerFooter.header.phone) }}
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col class="bottom pt-0 px-md-0" cols="12">
          <v-container class="pb-2 pt-4 px-md-0" :fluid="isSafari">
            <v-row justify="space-between" align="center">
              <v-col cols="auto" class="px-md-0">
                <nuxt-link to="/">
                  <v-img
                    width="200"
                    src="https://epd.directus.app/assets/8932063c-5a11-4ca1-88fe-8de66054f8b8"
                    srcset="https://epd.directus.app/assets/8932063c-5a11-4ca1-88fe-8de66054f8b8 1x, https://epd.directus.app/assets/8932063c-5a11-4ca1-88fe-8de66054f8b8 2x"
                    alt="Containerdienst.de" />
                </nuxt-link>
              </v-col>

              <v-col cols="auto" class="d-none d-lg-block">
                <nuxt-link :to="headerFooter.header.b2b_link ?? '#'" @click="closeMainMenu">
                  {{ headerFooter.header.b2b_label }}
                </nuxt-link>
              </v-col>

              <ClientOnly>
                <v-col cols="4" lg="3">
                  <v-text-field
                    id="main-menu-dropdown"
                    :placeholder="visitedFraction || t('select_waste_type')"
                    rounded="pill"
                    max-width="392px"
                    hide-details
                    :append-inner-icon="!isMainMenuOpen ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                    readonly
                    class="dropdown-input"
                    @click="toggleMainMenu" />
                </v-col>

                <v-col cols="auto" class="px-md-0">
                  <v-row align="center" class="badge">
                    <v-col cols="auto">
                      <div id="trustbadgeCustomContainer" />
                    </v-col>

                    <v-col cols="auto" class="px-md-0">
                      <button-header
                        v-if="pageSlug !== 'warenkorb'"
                        class="cards cart rounded"
                        density="comfortable"
                        @click="openSidebar">
                        <v-row no-gutters align="center" justify="space-around">
                          <v-col cols="auto">
                            <span class="text-h5 font-weight-bold">
                              {{
                                cart.endPriceForPayment.grossPrice
                                  ? formatCentToEuro(cart.endPriceForPayment.grossPrice)
                                  : '0,00'
                              }}
                              &euro;
                            </span>
                          </v-col>

                          <v-col cols="auto" class="pl-5">
                            <v-badge
                              :content="
                                cart.products.reduce((accu, curr) => accu + curr.quantity, 0)
                              "
                              offset-x="10"
                              offset-y="-8"
                              bordered>
                              <v-img
                                width="40"
                                :alt="t('your_shopping_cart')"
                                src="@/assets/icons/header/header-cart-container.svg" />
                            </v-badge>
                          </v-col>
                        </v-row>
                      </button-header>
                    </v-col>
                  </v-row>
                </v-col>
              </ClientOnly>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </VAppBar>
    <GlobalMainMenu :phone="headerFooter.header.phone" />
  </ContentWrapper>
</template>

<script setup lang="ts">
import sanitizePhone from '@/util/sanitize-phone'
import { ACTIVE_SIDEBAR_TYPE } from '~/types/enums'

const { isSafari } = useDevice()
const { formatCentToEuro } = useMoneyHelper()
const { t } = useI18n()
const route = useRoute()

const headerFooterStore = useHeaderFooterStore()
const { headerFooter } = storeToRefs(headerFooterStore)
if (headerFooter.value.header === null) await headerFooterStore.getHeaderFooterData()

const sidebarStore = useSidebarStore()
const { isMainMenuOpen } = storeToRefs(sidebarStore)

const fractionStore = useFractionStore()
fractionStore.getFractionData()
const { fractionsSorted } = storeToRefs(fractionStore)

const cartStore = useCartStore()
cartStore.getCart()
const { cart } = storeToRefs(cartStore)

const toggleMainMenu = () => sidebarStore.toggleMainMenu()
const closeMainMenu = () => sidebarStore.closeMainMenu()
const openSidebar = () => sidebarStore.openGlobalSidebar(ACTIVE_SIDEBAR_TYPE.CART)

const isHidden = ref(false)

const visitedFraction = computed(
  () =>
    fractionsSorted.value?.find(fraction => fraction.shop_slug === route.path.split('/')[1])
      ?.description,
)
const pageSlug = computed(() => route.path.split('/')[1])

const observerElement = ref(null)

onMounted(() => {
  const observer = new IntersectionObserver(
    entries => {
      const entry = entries[0]
      isHidden.value = !entry.isIntersecting && !isMainMenuOpen.value
    },
    {
      root: null,
      rootMargin: '120px 0px -100%',
    },
  )

  if (observerElement.value) {
    observer.observe(observerElement.value)
  }

  onUnmounted(() => {
    if (observerElement.value) {
      observer.unobserve(observerElement.value)
    }
  })
})
</script>

<style scoped lang="scss">
.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it is above other content */
  transition: transform 0.3s ease-in-out;
}
.safari {
  :deep(.v-container) {
    max-width: 90% !important;
    @media (min-width: 1800px) {
      max-width: 1600px !important;
    }
  }
}
:deep(.v-toolbar__content) {
  height: fit-content !important;
}
:deep(.v-field__input) {
  border-right: 1px solid rgb(var(--v-theme-border)) !important;
}
:deep(.v-field__overlay) {
  border: 1px solid rgb(var(--v-theme-border)) !important;
}
:deep(.v-field__append-inner .mdi) {
  width: 40px;
  padding-left: 10px;
  color: rgb(var(--v-theme-primary)) !important;
}
:deep(::placeholder) {
  color: rgb(var(--v-theme-black)) !important;
  opacity: 1 !important;
  font-weight: bold;
}
.hidden {
  transform: translateY(-100%) !important;
}
:deep(#trustbadgeCustomContainer > div > div > div) {
  border: #bacbdd 1px solid !important;
  position: relative !important;
  margin: inherit !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  color: inherit !important;
  line-height: inherit !important;
  text-align: inherit !important;
  box-shadow: none !important;
  float: none !important;
  display: block !important;
  left: unset !important;
  right: unset !important;
  bottom: unset !important;
  opacity: inherit !important;
  visibility: inherit !important;
  p {
    font-size: 10px !important;
  }
}
:deep(#trustbadgeCustomContainer > div > div > div > div > div > div) {
  transform: scale(0.8) !important;
}
:deep(#trustbadgeCustomContainer) {
  ._vbx68m {
    width: 140px !important;
    display: flex !important;
  }
  ._1bcyd8a {
    margin-bottom: 4px !important;
  }
  ._1ehsxge {
    width: 58px !important;
    height: auto !important;
  }
}

.observer-element {
  height: 1px;
  margin-top: -1px;
}
</style>
